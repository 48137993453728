.chatbot_icon {
  width: 55px !important;
  height: 55px !important;
  background: #ffc800 !important;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(60, 60, 60, 0.4);
  transition: box-shadow 0.2s;
  display: flex; /* Add flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 100;

  svg {
    width: 35px;
  }
}

.chatbot_button {
  position: relative;

  .chatbot_icon {
    cursor: pointer;
  }

  .dialogue_box {
    position: absolute;
    transform: translate(-90%, -113%);
    width: 373px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    // width: 80%;
    z-index: 1000;
    @media (max-width: 450px) {
      width: 340px;
    }

    h2 {
      font-size: 18px;
      margin-bottom: 20px;
    }

    form {
      .form_field {
        margin-bottom: 20px;

        label {
          display: block;
          font-size: 14px;
          margin-bottom: 5px;
          color: #333333;
        }

        input,
        textarea {
          width: 100%;
          padding: 10px;
          font-size: 14px;
          border: 1px solid #cccccc;
          border-radius: 4px;
          transition: border-color 0.3s ease;

          &:focus {
            outline: none;
            border-color: #ffc800;
          }
        }

        textarea {
          min-height: 100px;
          resize: vertical;
        }
      }

      button {
        background-color: #ffc800;
        color: #000000;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #ffbb00;
        }
      }
    }
  }
}

@import "../../../assets/styles/main.scss"; // Import color variables

.secondary_button {
  border-radius: 10px;
  background: $secondary-color;
  color: $primary-color;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px;

  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: $primary-color;
    z-index: -1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 10px;
  }

  // Define hover effect
  &:hover {
    background: $primary-color; // Change background color to primary color on hover
    color: $secondary-color;
    border-color: $primary-color;
    img.icon {
      filter: contrast(0%);
    }
    // Start transition from the right
  }
  @media (max-width: 768px) {
    font-size: 13px;
  }
}
.secondary_button:hover::before {
  width: 100%;
}

.enquiryForm {
  height: 500px;
  position: relative;
  overflow: hidden;
  @media (max-width: 990px) {
    height: 800px;
  }
  .outer-box {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
    // max-width: 800px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(30px);
    padding: 40px;
    width: 600px;
    @media (max-width: 990px) {
      width: 100%;
    }
    // width: 100%;
    .heading {
      color: #fff;
      font-size: 32px;
      font-weight: 700;
    }
    Label {
      color: #fff;
      // margin-top: 40px;
      font-family: Poppins;
      font-size: 15px;
      font-weight: 500;
      line-height: normal;
    }
  }

  .bg-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
    z-index: -1;
  }

  .bg-1 {
    background: url("../../../assets/images/common/enquiryForm1.jpg")
      center/cover;
  }

  .bg-2 {
    background: url("../../../assets/images/common/enquiryForm2.png")
      center/cover;
  }

  .bg-3 {
    background: url("../../../assets/images/common/enquiryForm3.png")
      center/cover;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
.button-form.active {
  background-color: #ffc800;
  border: 1px solid #ffc800;
}

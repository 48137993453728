.citywise-form {
  border-radius: 10px;
  background-image: url("../../../assets/images/common/citywiseEnquiry.png");
  // background: #fff;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  @media (max-width: 768px) {
    margin-top: 30px;
  }
  .blinking {
    animation: blinkingText 2s infinite;
  }
  .header {
    border-radius: 10px;
    background: var(--Primary-colour, #ffc800);

    color: #000;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 20px;
    text-align: center;
    @media (max-width: 768px) {
      padding: 10px;
      font-size: 14px;
    }
  }
  input {
    border-radius: 5px;
    border: 2px solid var(--Primary-colour, #ffc800);
    background: #fff;
  }

  textarea {
    border-radius: 5px;
    border: 2px solid var(--Primary-colour, #ffc800);
    background: #fff;

    &::placeholder {
      color: #000;
      font-size: 14px;
    }
  }
  textarea:focus {
    border: #ffc800;
    box-shadow: 0 0 0 0.25rem rgb(253 185 13 / 25%);
  }
  input:focus {
    border: #ffc800;
    box-shadow: 0 0 0 0.25rem rgb(253 185 13 / 25%);
  }
  input::placeholder {
    color: #000;
    font-size: 14px;
  }
  .ant-input {
    font-family: Poppins;
  }

  @keyframes blinkingText {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@import "../../../assets/styles/main.scss"; // Import color variables

.about_us {
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
  .welcome {
    color: $secondary-color;
    font-size: 24px;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  .title {
    font-size: 49px;
    font-weight: 600;
    line-height: 60px; /* 122.449% */
    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
  .description {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 38px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  .features {
    border-radius: 40px;
    border: 1.5px solid #999;
    padding: 7px 15px;
    margin-top: 77px;
    @media (max-width: 992px) {
      margin-top: 10px;
      justify-content: flex-start !important;
    }
    p {
      color: #000;
      margin-left: 10px;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
}

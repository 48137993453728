.custom_breadcrumb {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../../../assets/images/common/faq_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    height: 200px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
    @media (max-width: 380px) {
      div {
        flex-direction: column;
      }
    }

    .address {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: 400;
      flex-wrap: wrap;
      @media (max-width: 768px) {
        font-size: 15px;
      }
    }
  }
}

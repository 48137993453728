.benefits {
  margin-top: 120px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-top: 70px;
  }
  .title {
    color: #000;
    font-size: 49px;
    font-weight: 600;
    @media (max-width: 768px) {
      font-size: 30px;
    }
  }
  .description {
    color: #000;
    margin-top: 25px;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 18px;
      margin-top: 15px;
    }
  }
  .box {
    padding: 50px;
    margin-top: 75px;
    border-radius: 20px;
    width: 90%;
    background: linear-gradient(
      158deg,
      #3b3b3b 14.21%,
      #666 49.33%,
      #191818 115.23%
    );

    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    display: inline-flex;
    flex-direction: column;
    height: 75%;
    align-self: stretch;

    align-items: center;

    @media (max-width: 768px) {
      padding: 40px;
      margin-top: 40px;
    }
    img {
      width: 120px;

      @media (max-width: 768px) {
        width: 100px;
      }
    }
    // width: 40px;
    p {
      margin-bottom: 0;
      color: #fff;
      margin-top: 35px;
      font-size: 24px;
      font-weight: 500;
      @media (max-width: 1340px) {
        margin-top: 15px;
        font-size: 20px;
      }
      @media (max-width: 768px) {
        margin-top: 15px;
        font-size: 16px;
      }
    }
  }
}

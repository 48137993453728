.footer {
  padding: 85px 50px 85px 50px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url("../../../assets/images/common/footer_bg.jpeg") lightgray 0px -260.062px /
      100% 312.306% no-repeat;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
  .about_company {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  .contact_us {
    .heading {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    .sub_heading {
      color: var(--Primary-colour, #ffc800);
      font-size: 18px;
      font-weight: 700;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    .content {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
  .links {
    .heading {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
  }
  .footer-slider {
    border-radius: 20px;
    border: 3px solid #ffc800;
    margin-right: 10px;
  }
  .slick-slide {
    div {
      margin-right: 10px;
    }
  }
}
.copyright {
  // padding: 50px auto;
  background-color: #000;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  // width: 100%;
  padding: 30px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  .company_link {
    color: #ffc800 !important;
    font-weight: 600 !important;
  }
}

.hero_slider {
  position: relative;
  img {
    width: 100%;
    object-fit: cover;
    height: 80vh;
    @media (max-width: 768px) {
      // height: 50.2vh;
      height: 100vh;
      width: 100%;
      object-fit: cover;
    }
    @media (max-width: 425px) {
      // height: 50.2vh;
      height: 80vh;
      width: 100%;
      object-fit: cover;
    }
  }
}
.hero_content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
  // width: 80%;
  @media (max-width: 768px) {
    width: 80%;
  }
  .title {
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    font-size: 52px;
    font-weight: 700;
    margin-bottom: 0;
    @media (max-width: 1180px) {
      font-size: 40px;
    }
    @media (max-width: 1000px) {
      font-size: 30px;
    }
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
  .description {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
    @media (max-width: 1180px) {
      font-size: 16px;
    }

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}

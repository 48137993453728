.services-home {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .heading {
    font-size: 49px;
    font-weight: 600;
    margin-top: 50px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 36px;
      margin-top: 30px;
    }
  }
  .description {
    text-align: center;

    font-size: 20px;
    margin-top: 50px;

    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 14px;
      margin-top: 30px;
    }
  }
  .all_services {
    padding: 0px 150px;
    @media (max-width: 1630px) {
      padding: 0 100px;
    }
    @media (max-width: 1375px) {
      padding: 0 30px;
    }
    @media (max-width: 1200px) {
      padding: 0 0px;
    }
  }
  .service_box {
    border-radius: 20px;
    background: rgba(255, 212, 38, 0.2);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
    padding: 30px 30px 45px 30px;
    text-align: center;
    align-self: stretch;
    height: 100%;
    @media (max-width: 768px) {
      padding: 8px 8px 25px 8px;
    }

    img {
      width: 139px;
      height: 139px;
      @media (max-width: 768px) {
        width: 90px;
        height: 90px;
      }
    }
    .service_title {
      color: #000;
      font-size: 24px;
      margin-top: 38px;
      font-weight: 600;
      text-transform: uppercase;
      @media (max-width: 768px) {
        font-size: 18px;
        margin-top: 20px;
      }
    }
    .service_content {
      color: #000;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      margin-top: 38px;
      @media (max-width: 768px) {
        font-size: 12px;
        margin-top: 15px;
      }
    }
  }
}

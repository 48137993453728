.services {
  padding-bottom: 90px;
  // display: flex;
  flex-wrap: wrap;
  .our-services {
    border-radius: 10px;
    background: #fff;

    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    @media (max-width: 768px) {
      margin-top: 30px;
    }
    .header {
      border-radius: 40px;
      background: rgba(255, 212, 38, 0.25);
      color: #000;
      font-size: 24px;
      font-weight: 500;
      padding: 10px 30px;
      text-align: center;
      @media (max-width: 768px) {
        padding: 10px;
        font-size: 18px;
      }
    }
    ul {
      a {
        text-decoration: none;
        color: #000;
      }
      li {
        margin-top: 30px;
        font-size: 18px;
        font-weight: 400;
        text-decoration: none !important;
        &::marker {
          color: #ffc800;
          list-style-type: disc;
          font-size: 20px;
        }
        @media (max-width: 768px) {
          font-size: 12px;
          margin-top: 7px;
        }
      }
    }
  }
  .service_name {
    color: #000;
    font-size: 36px;
    font-weight: 600;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  .service_description {
    color: #000;

    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  .sub-head {
    color: #000;
    font-size: 36px;
    font-weight: 500;
    margin-top: 80px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  .why_choose {
    border-radius: 20px;
    margin-top: 40px;
    background: linear-gradient(158deg, #000 14.21%, #282200 117.19%);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 50px;
    height: 90%;
    align-self: stretch;
    @media (max-width: 768px) {
      padding: 24px;
    }
    .title {
      color: var(--Primary-colour, #ffc800);
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
    .content {
      color: #fff;
      margin-top: 30px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      @media (max-width: 768px) {
        font-size: 12px;
        margin-top: 15px;
      }
    }
  }
  .button-conclusion {
    border-radius: 40px;
    // border: 1px solid #000;
    box-shadow: 1px 1px 4px rgba(60, 60, 60, 0.4);
    padding: 10px;
    background: #ffc800;
    color: #000;
    img {
      animation: blinkingText 2s infinite;
    }
    @keyframes blinkingText {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      75% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Poppins;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-width {
  width: 50%;
}
@media (max-width: 400px) {
  .custom-width {
    width: 70%;
  }
}

@media (min-width: 768px) {
  .custom-width {
    width: 85%;
  }
}

p {
  margin-bottom: 0 !important;
}

.whatsapp-button-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 100;
}

.call-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

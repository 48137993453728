.city-wise {
  .box {
    border: 1px solid #e9e9e9;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0px 0px 6px #ccc;
    margin-top: 30px;
  }
  .icons {
    color: #000;
    display: inline-block;
    padding: 20px;
    text-align: left;
    text-transform: capitalize;
    margin-right: 8px;
    font-size: 16px;
    font-weight: 600;
    background-color: #ffc800;
  }
}

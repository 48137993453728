.faq {
  .heading {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 49px;
    font-style: normal;
    font-weight: 600;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  .faq-accordion {
    background: rgba(255, 212, 38, 0.2);
    padding: 140px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 12px;
      padding: 20px;
    }
  }
  .accordion-item {
    border-radius: 10px !important;
    border: 1px solid #c7c7c7 !important;
    color: #6b6b84;
    button {
      border-radius: 10px !important;
      border: 0px solid #c7c7c7 !important;
      font-size: 20px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
  .outer-box {
    padding: 58px 66px 58px 67px;
    margin-top: 70px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    @media (max-width: 768px) {
      padding: 20px;
      margin-top: 40px;
    }
  }
}

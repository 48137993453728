.testimonials {
  .outer-div {
    border-radius: 20px;
    border: 1px solid #e1e1e1;
    background: #f4f4f4;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 65px;
    @media (max-width: 768px) {
      padding: 20px;
    }
  }
  .heading {
    color: #000;
    text-align: center;
    font-size: 49px;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 25px;
    }
  }
  .sub-head {
    color: #999;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  .bg-primary {
    background: rgba(255, 212, 38, 0.25) !important;
  }
  .content {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  .m--3 {
    margin-top: -45px;
  }
  .margin-bottom {
    margin-top: 30px;
    @media (max-width: 768px) {
      margin-top: 100px;
    }
  }
  .user_image {
    width: 84px;
    height: 84px;
    border-radius: 84px;
  }
}

.whatsapp_button {
  width: 55px !important;
  height: 55px !important;
  background: #25d366 !important;
  z-index: 100;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(60, 60, 60, 0.4);
  transition: box-shadow 0.2s;
  display: flex; /* Add flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  img {
    width: 35px;
  }
}

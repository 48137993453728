.tracking {
  background: rgba(255, 212, 38, 0.2);
  .outer-box {
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      color: #000;
      text-align: center;
      font-size: 44px;
      //   margin-top: 50px;
      margin-bottom: 30px;
      font-weight: 600;
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
  }
  .inner-box {
    background-color: white;
    text-align: center;
    // width: max-content; /* To center the inner box horizontally */
    margin: 0 auto;
    padding: 50px; /* Adjust padding as needed */
    border-radius: 20px;
    background: #fff;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    @media (max-width: 768px) {
      padding: 15px;
    }
  }
}

.partners-directors {
  .heading {
    color: #000;
    font-size: 36px;
    font-weight: 600;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  .directors {
    @media (max-width: 768px) {
      text-align: center;
    }
    .director_profile {
      border-radius: 20px;
      border: 2px solid #d6d6d6;
      width: max-content;
      margin: 20px 0px;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    }
    .name {
      background: var(--tiles, rgba(255, 212, 38, 0.2));
      color: #000;
      font-size: 22px;
      font-weight: 500;
      line-height: 35px; /* 159.091% */
      text-transform: capitalize;
      @media (max-width: 768px) {
        font-weight: 18px;
      }
    }
  }
}

.clients {
  padding-bottom: 80px;
  .client_logo {
    border-radius: 10px;
    border: 1px solid #bbb;
  }
  .custom-col {
    @media (max-width: 768px) {
      justify-content: center;
      align-items: center;
    }
  }
}

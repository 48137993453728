.terms-and-conditions {
  .custom {
    padding: 150px 0px;
  }
  .heading {
    color: #000;
    font-size: 24px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .sub-head {
    font-size: 18px;
    font-weight: 500;
  }
  ul {
    li {
      margin-top: 13px;
    }
  }
}

.about_us {
  .heading {
    color: #000;
    margin-top: 65px;
    text-align: center;
    font-family: Poppins;
    font-size: 49px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
  .border-box {
    border-radius: 20px;
    border: 1.5px solid #dbdbdb;
    padding: 47px;
    @media (max-width: 768px) {
      padding: 20px;
    }
  }
  .sub-head {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
  .desc {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  .outer-box-about {
    border-radius: 20px;
    background: linear-gradient(158deg, #000 14.21%, #282200 117.19%);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 39px 29px 64px 29px;
    // height: 500px;

    @media (max-width: 768px) {
      padding: 20px 15px;
    }
    .title {
      color: var(--Primary-colour, #ffc800);
      text-align: center;
      font-size: 28px;
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    .content {
      color: #fff;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
  .horizontal_box {
    padding: 39px 19px;
    border-radius: 20px;
    background: var(
      --tile,
      linear-gradient(158deg, #000 14.21%, #282200 117.19%)
    );
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    @media (max-width: 768px) {
      padding: 27px 14px 28px 14px;
    }
    .header {
      color: var(--Primary-colour, #ffc800);
      text-align: center;
      font-family: Poppins;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    .content {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}

.object-fit-contain {
  object-fit: contain;
}

.contact-us {
  .heading {
    color: #000;
    font-size: 36px;
    font-weight: 600;
    @media (max-width: 768px) {
      font-size: 24px;
      text-align: center;
    }
  }
  .sub-head {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-top: 13px;
    line-height: 3rem;
    border-bottom: 1px solid #ddd;
    // width: 50vh;
    @media (max-width: 768px) {
      font-size: 12px;
      text-align: center;
    }
  }
  .contact_info {
    align-items: center;
    @media (max-width: 480px) {
      flex-direction: column;
      text-align: start;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .content {
      color: #000;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      line-height: normal;
      @media (max-width: 768px) {
        font-size: 14px;
      }
      .title {
        font-weight: 700;
      }
      .data {
        font-weight: 400;
      }
    }
  }
}

.aspect-ratio {
  position: relative;
  width: 100%;
  height: 400px;

  &-16by9 {
    padding-top: 56.25%; // 16:9 aspect ratio
  }

  &-4by3 {
    padding-top: 75%; // 4:3 aspect ratio
  }

  // Add more aspect ratios as needed
}

// Apply custom aspect ratio to iframe
.aspect-ratio iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  border: 0;
}
